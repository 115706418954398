body {
  margin: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.check-list {
  display: flex;
  justify-content: center;
  width: 70%;
  padding: 0 15%;
  flex-direction: column;
}

.check-box {
  padding: 5px, 5px;
}

.Checklist-title {
  margin: 20px 0;
  padding: 20px 0;
}

.list-item {
  padding: 10px 0;
}
